<template>
    <div id="users">
        <!-- 用户列表卡片区 -->
        <el-card class="box-card">

            <el-form :inline="true" :model="queryMap" label-width="70px" size="small">
                <el-form-item label="软件名称">
                    <el-input clearable
                              v-model="queryMap.name"
                              placeholder="请输入软件名称"
                    ></el-input>
                </el-form-item>
                <el-form-item style="margin-left:50px;">
                    <el-button @click="reset" icon="el-icon-refresh"></el-button>
                    <el-button @click="getSoftwares" icon="el-icon-search"></el-button>
                </el-form-item>
            </el-form>

            <div class="card-shop">
                <el-card class="box" shadow="hover" v-for="(item) in softwareList" :key="item.id" :offset="1">
                    <div slot="header" class="clearfix" style="height: 40px;text-align: center; ">
                        {{item.name}}
                        <span v-if="item.status==0" style="color: #ff4400">可试用</span>
                        <div style="font-size: 13px; color: #999999" v-if="item.softwareVersionList.length > 0">
                            {{item.softwareVersionList[0].version}}
                        </div>
                        <div style="font-size: 13px; color: #999999" v-if="item.softwareVersionList.length > 0">
                            {{item.softwareVersionList[0].softwareSize}}(byte)
                        </div>
                    </div>
                    <div class="set-content">
                        <div style="font-size: 16px; color: #999999">&#12288;&#12288;{{item.introduction}}</div>
                    </div>
                    <div class="rigdown">
                        <el-button size="small" @click="open(item.description,item.name)" type="info" plain>详细
                        </el-button>
                        <el-button size="small" @click="edit(item.name,item.status,item.id)" type="success" plain>
                            申请
                        </el-button>
                        <el-button size="small" @click="softwareById(item.id)" type="primary" plain>下载安装</el-button>
                    </div>
                </el-card>
            </div>
            <el-drawer
                    :title="this.name"
                    :visible.sync="drawer"
                    :direction="direction"
                    size="35%"
            >
                <div style="margin: auto; margin-top: 13%; margin-left: 10%">
                    <el-card class="box-card" style="width: 90%">
                        <div v-html="this.description"></div>
                    </el-card>
                </div>
            </el-drawer>
            <el-dialog class="class_dialog_hospital" title="详细" :visible.sync="viewDialogFormVisible" width="100%;">
                <el-card class="box-card">
                    <el-form
                            :model="addForm"
                            :label-position="labelPosition"
                            ref="addFormRef"
                            label-width="140px">
                        <el-form-item label="软件名称:">
                            <span>{{name}}</span>
                        </el-form-item>
                        <el-form-item label="是否可试用" prop="status">
                            <template slot-scope="scope">
                                <el-tag type="success" v-if="scope.row.status==0">是</el-tag>
                                <el-tag type="info" v-if="scope.row.status==1">否</el-tag>
                            </template>
                        </el-form-item>
                    </el-form>
                </el-card>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="closeSoftwareDetail">关闭</el-button>
                </span>
            </el-dialog>
        </el-card>

        <el-dialog :title="dialogTitle" class="class_dialog_hospital" @close="closeDialog"
                   :visible.sync="DialogVisible">
            <el-card class="box-card">
                <el-form
                        :model="addForm"
                        :label-position="labelPosition"
                        :rules="addFormRules"
                        ref="addFormRef"
                        label-width="90px">
                    <el-form-item label="类别:" prop="type" label-width="90px">
                        <el-radio-group v-model="addForm.type" @change="editLabel">
                            <el-radio v-if="addForm.softStuats===0" :label="0">试用</el-radio>
                            <el-radio :label="1">正式</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label-width="90px">
                        <el-table v-loading="loading" size="small" :data="tableData" border style="width: 555px; "
                                  @selection-change="handleSelectionChange">
                            <el-table-column type="selection" width="50"></el-table-column>
                            <el-table-column prop="ukeyId" width="120" show-overflow-tooltip
                                             label="Ukey"></el-table-column>
                            <el-table-column prop="agentId" width="120" show-overflow-tooltip
                                             label="Agent端末"></el-table-column>
                            <el-table-column prop="status" width="100" show-overflow-tooltip label="赋权状态">
                                <template slot-scope="scope">
                                    <el-tag type="success" v-if="scope.row.grant==true">已赋权</el-tag>
                                    <el-tag type="info" v-if="scope.row.grant==false">未赋权</el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column prop="dueDate" width="150" show-overflow-tooltip
                                             label="使用期限"></el-table-column>
                        </el-table>
                    </el-form-item>
                    <el-form-item label="申请说明:" prop="applyExplain" label-width="90px">
                        <el-input type="textarea" v-model="addForm.applyExplain" maxlength="200" :rows="5"></el-input>
                    </el-form-item>
                </el-form>
            </el-card>
            <span slot="footer" class="dialog-footer">
                   <el-button size="small" type="primary"
                              icon="el-icon-check"
                              @click="addApply(addForm) ">申请
                   </el-button>
                   <el-button size="small" type="warning"
                              icon="el-icon-close"
                              @click="closeDialog">取消
                   </el-button>
             </span>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                softStuats: '',
                viewDialogFormVisible: false,
                DialogVisible: false,
                dialogTitle: '',
                description: '',
                name: '',
                stuats: '',
                total: null,
                drawer: false,
                direction: 'rtl',
                queryMap: {},
                labelPosition: "right",
                a: 1,
                detailDialogVisible: false,
                addDialogVisible: false,
                softwareList: [],
                agents: [
                    // {id: 1, name: "agent1", status: 0, ukey: "12346"},
                    // {id: 2, name: "agent2", status: 1, ukey: "45679"},
                    // {id: 3, name: "agent3", status: 2, ukey: "13798"},
                ],
                table: [
                //     {
                //     Agent: 'xxx',
                //     ukeyId: 'ukeyxxx',
                //     status: 0,
                //     useTime: "2023-02-23"
                // }, {
                //     Agent: 'yyy',
                //     ukeyId: 'ukeyyyy',
                //     status: 1,
                //     useTime: "2023-03-30"
                // }
                ],
                downloadDay: "",
                addForm: {},
                loading: true,
                tableData: [], //临时组
                spanArr: [],
                pos: null,
                options: [
                    {id: 0, name: "试用"},
                    {id: 1, name: "正式"}
                ],
                ukeyList: [],
                addFormRules: {
                    applyExplain: [
                        {required: true, message: '请输入申请说明', trigger: 'blur'}],
                    type: [
                        {required: true, message: '请选择类别', trigger: 'blur'}
                    ],
                },

            };
        },
        methods: {


            /**
             * 添加申请
             */
            async addApply(addForm) {
                this.$refs.addFormRef.validate(async valid => {
                    if (!valid) {
                        return;
                    } else {
                        if (this.ukeyList.length == 0) {
                            return this.$notify.error({
                                title: '操作失败',
                                message: '请选择ukey'
                            });
                        } else {
                            this.addForm.ukeyId = this.ukeyList.toString();
                            const {data: res} = await this.$http.post("/factorymng/apply", this.addForm);
                            if (res.success) {
                                this.$notify.success({
                                    title: '操作成功',
                                    message: '添加成功',
                                });
                                this.addForm = {};
                                this.ukeyList=[];
                                this.getSoftwares();
                            } else {
                                return this.$message.error(res.data != null ? res.data : res.data.errorMsg);
                            }
                        }}
                    this.DialogVisible=false;
                });
            },

            /**
             * 遍历
             **/
            handleSelectionChange(selection) {
                this.ukeyList = [];
                selection.forEach(item => {
                    this.ukeyList.push(item.ukeyId);
                });
            },

            /**
             * 右侧抽屉
             * @param description
             * @param name
             */
            open(description, name) {
                this.drawer = true;
                this.description = description;
                this.name = name;
            },

            /**
             * 关闭软件详细
             **/
            closeSoftwareDetail() {
                this.viewDialogFormVisible = false
            },

            /**
             * 软件详细
             */
            async apply() {
                this.$router.push({
                    path: '/applyView/apply',
                })
            },

            /**
             * 跳转页面携带软见id
             * @param id
             */
            softwareById(id) {
                this.$router.push({
                    path: '/softwareView/software',
                    query: {
                        softwareId: id
                    }
                })
            },

            /**
             * 申请
             **/
            async edit(name, status,id) {
                this.addForm.type = 1;
                this.DialogVisible = true;
                this.dialogTitle = "申请";
                this.addForm.name = name;
                this.addForm.softStuats = status;
                this.addForm.softId=id
                const {data: res} = await this.$http.get("/factorymng/ukey/canapply/list?softId="+ id);
                this.tableData = res.data;
            },

            /**
             * 失去焦点触发
             **/
            editLabel() {
                this.$forceUpdate();
            },

            /**
             * 软件列表
             */
            async getSoftwares() {
                this.spanArr = []
                this.pos = null
                this.tableData = []
                const {data: res} = await this.$http.get("/factorymng/software/list", {
                    params: this.queryMap
                });
                this.softwareList = res.data;
                this.total = res.data;
            },

            /**
             * 表格显示
             **/
            getFileName(name) {
                if (name == undefined || name == "") {
                    return "";
                }
                let nameList = name.split("/");
                return nameList[nameList.length - 1];
            },
            //合并单元格操作
            objectSpanMethod({row, column, rowIndex, columnIndex}) {
                if (columnIndex === 0) {

                    const _row = this.spanArr[rowIndex];
                    const _col = _row > 0 ? 1 : 0;
                    return { // [0,0] 表示这一行不显示， [2,1]表示行的合并数
                        rowspan: _row,
                        colspan: _col
                    }
                }
            },

            /**
             * 重置
             */
            reset() {
                this.queryMap = {
                    currentPage: 1,
                    pageSize: 10
                };
                this.getSoftwares()
            },

            /**
             * 关闭添加弹出框
             */
            closeDialog() {
                this.DialogVisible = false;
                this.addForm = {};
            },
        },

        created() {
            this.getSoftwares();
            setTimeout(() => {
                this.loading = false;
            }, 2000);
        }
    }
    ;
</script>

<style scoped>
    .text {
        font-size: 14px;
    }

    .clearfix:before,
    .clearfix:after {
        display: table;
        content: "";
    }

    .clearfix:after {
        clear: both
    }

    .box {
        width: 263px;
        height: 350px;
        margin: 20px;
    }

    .card-shop {

        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
    }

    .rigdown {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;


    }

    .set-content {
        margin-top: 10%;
        height: 180px;
        word-break: break-word;
        white-space: pre-line;
        display: flex;
    }


</style>
